<template>
  <!-- 智慧城市 -->
  <div class="content-box">
    <div class="title-box">
      <div class="title-name">智慧城市</div>
      <div class="title-p">
        “新型智慧城市”是适应新型智慧城镇化建设，以信息化创新引领城市发展转型，全面推进新一代信息通信技术与城市发展融合创新，
        加快工业化、信息化、城镇化、农业现代化融合，提高城市治理能力现代化水平，实现城市可持续发展的新路径、新模式、新形态。
      </div>
    </div>
    <div class="profiles-box">
      <div class="profiles-title">
        <div class="text">概况</div>
      </div>
      <div class="profiles-content">
        <div class="item">
          <div class="item-title">新理念</div>
          <div class="item-content">
            <p>创新、协同、绿色、开放、</p>
            <p>共享的发展理念；</p>
            <p>以人为本的服务化理念；</p>
            <p>一切为了服务。</p>
          </div>
        </div>
        <div class="item">
          <div class="item-title">新思维</div>
          <div class="item-content">
            <p>“互联网”思维，“物联网”思维，</p>
            <p>大数据思维；一切靠大数据说话，</p>
            <p>凭大数据决策。</p>
          </div>
        </div>
        <div class="item">
          <div class="item-title">新方法</div>
          <div class="item-content">
            <p>系统科学与系统工程方法，</p>
            <p>“分级分类”逐步推进建设。</p>
          </div>
        </div>
        <div class="item">
          <div class="item-title">新目标</div>
          <div class="item-content">
            <p>提高城市治理能力现代化水平，</p>
            <p>实现城市可持续发展。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="function">
      <div class="function-title">平台架构</div>
      <img src="../../assets/image/cityImage/组 220.png" alt="" width="100%" />
    </div>
    <div class="function">
      <div class="function-title">功能场景</div>
    </div>
    <div class="scene">
      <h1></h1>
      <div class="img2" @click="go(1)">
        <div class="sceneBox">
          <div class="sceneIcon icon1"></div>
          <div class="sceneTitle">数据中心</div>
          <div class="sceneContent">
            <span></span>城市大数据中心<br />
            <span></span>国家A级计算机机房<br />
            <span></span>国家三级顶保标准(Tier3+)<br />
            <span></span>超大机房<br />
            <span></span>快速部署<br />
            <span></span>高设备利用率<br />
            <span></span>数据完整备份低风险<br />
            <span></span>电信级别托管，一站式运维<br />
            <span></span>集中托管到城市大数据中心平台，低能耗
          </div>
        </div>
      </div>
      <div class="img3" @click="go(2)">
        <div class="sceneBox">
          <div class="sceneIcon icon2"></div>
          <div class="sceneTitle">时空大数据与云平台</div>
          <div class="sceneContent">
            <span></span>高效的海量数据自动化实景三维建模<br />
            <span></span>多源数据的融合与重构<br />
            <span></span>全域空间的一张图展示<br />
            <span></span>高精度、无缝位置服务<br />
            <span></span>新型产业驱动
          </div>
        </div>
      </div>

      <div class="img4" @click="go(3)">
        <div class="sceneBox">
          <div class="sceneIcon icon3"></div>
          <div class="sceneTitle">运行指挥中心</div>
          <div class="sceneContent">
            整合各委办局的信息化系统,实现部门、区域之间的数据关联和共享,消除“信息孤岛”
            对数据进行融合处理、深度挖掘、决策分析，是智慧城市的核心中枢。
          </div>
        </div>
      </div>
      <div class="img5" @click="go(4)">
        <div class="sceneBox">
          <div class="sceneIcon icon4"></div>
          <div class="sceneTitle">智慧城市APP</div>
          <div class="sceneContent">
            VR、AR等新型产业核心平台<br />
            数据银行：双向数据服务，用户本身也可称为生产人员，分享平台收益人工智能汇聚平台，基础应用工具+公众创新应用=众包人工智能服务
          </div>
        </div>
      </div>
      <div class="img6" @click="go(5)">
        <div class="sceneBox">
          <div class="sceneIcon icon5"></div>
          <div class="sceneTitle">平安城市</div>
          <div class="sceneContent">
            <span></span>治安监控前端<br />
            <span></span>乡村监控前端<br />
            <span></span>实景作战系统<br />
            <span></span>微卡口系统<br />
            <span></span>人脸识别系统<br />
            <span></span>综治视频监控共享平台<br />
            <span></span>公安治安综合管控平台<br />
            <span></span>公安指挥调度系统<br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    go(i) {
      if (i == 1) {
        this.$router.push("wisdom");
      }
      if (i == 2) {
        this.$router.push("spaceTime");
      }
      if (i == 3) {
        this.$router.push("command");
      }
      if (i == 4) {
        this.$router.push("app");
      }
      if (i == 5) {
        this.$router.push("safetys");
      }
    },
  },
  created() {},
  mounted() {},
};
</script> 

<style scoped lang="scss">
.content-box {
  width: 100%;
  overflow: hidden;
  .title-box {
    width: 100%;
    color: #fff;
    height: 530px;
    padding: 0 160px;
    background: url("../../assets/image/cityImage/bg.png") no-repeat;
    .title-name {
      font-size: 44px;
      font-weight: normal;
      color: #ffffff;
      transform: translateY(190px);
    }
    .title-p {
      width: 55%;
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      transform: translateY(220px);
      color: #ffffff;
    }
  }
  .profiles-box {
    .profiles-title {
      width: 100%;
      height: 80px;
      background: #3651d8;
      .text {
        font-size: 28px;
        padding: 20px 160px;
        font-weight: normal;
        color: #ffffff;
      }
    }
    .profiles-content {
      height: 230px;
      display: flex;
      padding: 0 10%;
      background: #ffffff;
      .item {
        width: 25%;
        text-align: center;
        .item-title {
          font-size: 28px;
          padding: 30px 0;
          font-weight: normal;
          color: #1864e3;
        }
        .item-content {
          font-size: 16px;
          font-weight: normal;
          color: #6b6b6b;
        }
      }
    }
  }
  .function {
    padding: 0 10%;
    .function-title {
      text-align: center;
      padding: 30px 0;
      font-size: 40px;
      font-weight: 600;
      color: #333333;
    }
  }
}
.scene {
  margin: 0 10%;
  display: flex;
  margin-bottom: 50px;
  overflow: hidden;
  background-color: #333;
  > div {
    flex: 1;
    &:hover {
      transform: scale(1.07, 1.07);
    }
  }
  .sceneBox {
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.5);
      opacity: 0.8;
      .sceneIcon {
        animation: moves 0.5s linear;
        animation-fill-mode: forwards;
      }

      .sceneContent {
        display: block;
      }
      @keyframes moves {
        0% {
        }
        100% {
          margin-top: 100px;
        }
      }
    }
    // flex: 1;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-top: 110px;
    box-sizing: border-box;
    .sceneIcon {
      margin-top: 66%;
      width: 44px;
      height: 44px;
      z-index: 99;
    }
    .sceneTitle {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin: 5px 0;
      opacity: 1;
      z-index: 99;
      box-sizing: border-box;
      padding-bottom: 10px;
      border-bottom: 2px solid #fff;
    }
    .sceneContent {
      color: #fff;
      z-index: 99;
      display: none;
      box-sizing: border-box;
      // padding: 5% 10%;
      font-size: 14px;
      margin: 5% 10% 0 15%;
      > span {
        display: inline-block;
        width: 8px;
        height: 8px;
        // border-radius: 8px;
        line-height: 8px;
        transform: translateX(-100%) translateY(10%) rotate(45deg);
        background: #fff;

        // width: 48%;
        // height: 30px;
        // line-height: 30px;
        // text-align: center;
        // background: rgba(168, 168, 168, 0.5);
        // border-radius: 0px;
        // margin: 15px 1% 0 0;
        // color: #fff;
        // font-size: 10px;
        // font-family: PingFang SC;
        // font-weight: 400;
      }
    }
  }
}
.img2 {
  background: url("../../assets/image/cityImage/蒙版组 138.png") no-repeat;
  background-size: 100% 100%;
}
.img3 {
  background: url("../../assets/image/cityImage/蒙版组 139.png") no-repeat;
  background-size: 100% 100%;
}
.img4 {
  background: url("../../assets/image/cityImage/蒙版组 140.png") no-repeat;
  background-size: 100% 100%;
}
.img5 {
  background: url("../../assets/image/cityImage/蒙版组 141.png") no-repeat;
  background-size: 100% 100%;
}
.img6 {
  background: url("../../assets/image/cityImage/蒙版组 142.png") no-repeat;
  background-size: 100% 100%;
}
.icon1 {
  background: url("../../assets/image/cityImage/组 203.png") no-repeat;
  background-size: 100% 100%;
}
.icon2 {
  background: url("../../assets/image/cityImage/组 213.png") no-repeat;
  background-size: 100% 100%;
}
.icon3 {
  background: url("../../assets/image/cityImage/组 200.png") no-repeat;
  background-size: 100% 100%;
}
.icon4 {
  background: url("../../assets/image/cityImage/组 214.png") no-repeat;
  background-size: 100% 100%;
}
.icon5 {
  background: url("../../assets/image/cityImage/组 215.png") no-repeat;
  background-size: 100% 100%;
}
</style>